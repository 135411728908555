import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const JoinedGroupsPreview = observer(() => {
  const styles = useStyles();
  const { userStore } = useStore();
  const { MY_GROUPS_PATH } = usePath();

  const groups = userStore?.currentUserGroups;
  const totalMembersCount = groups.reduce((acc, group) => {
    return acc + (group?.membersCount || 0);
  }, 0);

  if (groups.length === 0) {
    return null;
  }

  return (
    <Box sx={styles.wrapper} component={Link} to={MY_GROUPS_PATH}>
      <Box sx={styles.titleContainer}>
        <Typography variant='body4'>{`You have joined ${groups.length} Group${
          groups.length > 1 ? 's' : ''
        }`}</Typography>
        <Box sx={{ mt: 'auto', pt: 1 }}>
          <Button size='small' variant='contained' color='primary'>
            See my Groups
          </Button>
        </Box>
      </Box>
      <Box>
        <AvatarGroup
          total={groups.length}
          max={9}
          variant='rounded'
          sx={{
            '& .MuiAvatarGroup-avatar': {
              width: '64px',
              height: '64px',
              backgroundColor: colors.secondary.main
            }
          }}
        >
          {groups.map(group => (
            <Avatar
              key={group.id}
              alt={group.name}
              variant='rounded'
              src={group.backgroundImageUrl}
              sx={{
                width: '64px',
                height: '64px'
              }}
            />
          ))}
        </AvatarGroup>
      </Box>
    </Box>
  );
});
