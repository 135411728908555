import React, { lazy, Suspense, useState } from 'react';

import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { SignUpModalLink } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import HeroDecorIcon from '@packs/images/svg/ui/hero-decor.svg';
import HeroImageIcon from '@packs/images/ui/hero-image.webp';
import HeroImage from '@packs/images/ui/landing/hero-image.webp';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { DiscoverAll } from './components/DiscoverAll/DiscoverAll';
import { Popular } from './components/Popular/Popular';

const HeroAnimation = lazy(() =>
  import('@packs/screens/Landing/components/Hero/components/HeroAnimation/HeroAnimation')
    .then(({ HeroAnimation }) => ({ default: HeroAnimation }))
    .catch(() => ({ default: <div /> }))
);

const { white, secondary } = colors;

export const Hero = () => {
  const { currentUser } = useUserSelectors();
  const { isMobile } = useWindowSize();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      pb={5}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundImage: secondary.gradient,
        pt: {
          xs: '80px',
          lg: '98px'
        },
        pb: {
          xs: 3,
          lg: 5
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          pt: {
            lg: 5
          }
        }}
      >
        <Box
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center 55px',
            backgroundImage: {
              xs: `url(${HeroDecorIcon})`,
              md: 'none'
            }
          }}
        >
          <Slide direction='up' in={true} timeout={700}>
            <Box
              sx={{
                position: 'relative',
                zIndex: '2'
              }}
            >
              <Typography variant='h1' color={white.main} textAlign='center' mb={2} px={{ xs: 2 }}>
                Your Trusted Place For Household Bills!
              </Typography>
              <Typography variant='subtitle1' color={white.main} textAlign='center' px={{ xs: 2 }}>
                Join your local BillWinner residential group for smarter savings on your bills!
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                {!currentUser.id && <SignUpModalLink size='large'>Join us!</SignUpModalLink>}
              </Box>
            </Box>
          </Slide>
          {isMobile ? (
            <Box mt={3}>
              <img src={HeroImageIcon} alt='Hero' />
            </Box>
          ) : (
            <Box
              sx={{
                position: 'relative',
                mt: '-12%',
                maxWidth: '100%',
                zIndex: '1',
                '& .hero-image': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  zIndex: '1'
                }
              }}
            >
              <Suspense fallback={<Box sx={{ width: '100%', pt: '43.3%' }} />}>
                <HeroAnimation />
              </Suspense>

              <img src={HeroImage} alt='Hero' className='hero-image' />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            position: 'relative',
            mt: {
              xs: 0,
              md: '-16px'
            },
            zIndex: '3'
          }}
        >
          <Box
            sx={{
              position: {
                md: 'absolute'
              },
              left: '50%',
              bottom: 'calc(100% + 24px)',
              display: 'flex',
              justifyContent: 'center',
              mb: {
                xs: 2,
                md: 0
              },
              transform: {
                md: 'translateX(-50%)'
              }
            }}
          >
            <Tabs value={value} onChange={handleChange} variant='buttonTabs'>
              <Tab label='Popular Groups' variant='buttonTabs' />
              <Tab label='All Leaderboards!' variant='buttonTabs' />
            </Tabs>
          </Box>
          {value === 0 && (
            <Slide direction='right' in={true} timeout={700}>
              <Box>
                <Popular />
              </Box>
            </Slide>
          )}
          {value === 1 && (
            <Slide direction='right' in={true} timeout={700}>
              <Box>
                <DiscoverAll />
              </Box>
            </Slide>
          )}
        </Box>
      </Box>
    </Box>
  );
};
