import React, { FC, useMemo } from 'react';

import { Box, Checkbox, Divider, FormControlLabel, Typography, TypographyProps } from '@mui/material';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import { DashboardGroupsFilter } from '@packs/components/UI/Dashboard/DashboardGroupsFilter/DashboardGroupsFilter';
import { DashboardLocalityFilter } from '@packs/components/UI/Dashboard/DashboardLocalityFilter/DashboardLocalityFilter';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useCategories } from '@packs/models/category/useCategories';
import { defaultLocality, offerBusinessType, offerTypes } from '@packs/models/company/constants';
import { currentCountryRegionOptions } from '@packs/models/company/currentCountryRegionOptions';
import { offersTabs } from '@packs/models/offer/constants';
import { useStore } from '@packs/stores';
import { defaultCategory } from '@packs/stores/OffersStore';

import { observer } from 'mobx-react';

type Props = {
  showFilters: string[];
  sidebarFilters?: boolean;
  setActiveOfferTab?: (key: string) => void;
};

export const MyOffersFilter: FC<Props> = observer(
  ({
    showFilters = ['offerType', 'categoryId', 'rootCategoryCode', 'customerType'],
    sidebarFilters = false,
    setActiveOfferTab
  }) => {
    const { offersStore, currentCountry } = useStore();
    const { flattenCategoriesOptions, rootCategoriesOptions } = useCategories();
    const { currentUser } = useUserSelectors();

    const filters = offersStore?.filters;

    const rootCategoriesList = [defaultCategory, ...rootCategoriesOptions];
    const categoriesList = useMemo(() => {
      if (!filters?.rootCategoryCode) {
        return [defaultCategory];
      }

      const filteredList = flattenCategoriesOptions.filter(item => item.rootCode === filters?.rootCategoryCode?.value);
      return [defaultCategory, ...filteredList];
    }, [filters?.rootCategoryCode]);

    const offerTypesList = useMemo(() => {
      let list = [...offerTypes];

      if (!currentUser.id) {
        list = list.filter(({ value }) => value !== 'trust_network');
      }

      return list;
    }, []);

    const regionOptions = currentCountryRegionOptions(currentCountry);

    const disableCategorySelect = !filters?.rootCategoryCode || filters?.rootCategoryCode?.value === 'all';

    const changeFilter = (key, value) => {
      const newFilters = {
        [key]: value
      };

      if (key === 'campaignGroupId') {
        if (value?.group?.countryRegion) {
          newFilters['location'] = {
            value: value?.group?.countryRegion?.name,
            label: value?.group?.countryRegion?.name
          };
        }
        if (value?.group?.locality) {
          newFilters['locality'] = {
            value: value?.group?.locality?.name,
            label: value?.group?.locality?.name
          };
        }
        if (setActiveOfferTab) {
          setActiveOfferTab(offersTabs[0].value);
        }
      }
      if (key === 'location') {
        newFilters['locality'] = null;
        if (setActiveOfferTab) {
          setActiveOfferTab(offersTabs[0].value);
        }
      }
      if (key === 'locality') {
        if (setActiveOfferTab) {
          setActiveOfferTab(offersTabs[0].value);
        }
      }
      if (key === 'rootCategoryCode') {
        offersStore.load({ ...newFilters, categoryId: defaultCategory }, false);
      } else {
        offersStore.load(newFilters, false);
      }
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {showFilters.some(item => item === 'rootCategoryCode') && (
          <>
            <AutocompleteMUI
              value={rootCategoriesList.find(item => item.value === filters?.rootCategoryCode?.value)}
              options={rootCategoriesList}
              onChange={data => changeFilter('rootCategoryCode', data)}
              label='Сategory'
              withTitle={sidebarFilters}
              size={sidebarFilters ? 'small' : 'medium'}
              mobileSelect
              filter
            />
          </>
        )}
        {showFilters.some(item => item === 'categoryId') && (
          <>
            {!sidebarFilters && <Divider />}
            <AutocompleteMUI
              value={categoriesList.find(item => item.value === filters?.categoryId?.value)}
              options={categoriesList}
              onChange={data => {
                if (!disableCategorySelect) {
                  changeFilter('categoryId', data);
                }
              }}
              disabled={disableCategorySelect}
              label='Sub category'
              withTitle={sidebarFilters}
              size={sidebarFilters ? 'small' : 'medium'}
              mobileSelect
              filter
            />
          </>
        )}
        {showFilters.some(item => item === 'offerType') && (
          <>
            {!sidebarFilters && <Divider />}
            <Box>
              <Typography variant={'body6' as TypographyProps['variant']}>Type of offer:</Typography>
              <MaterialRadioGroup
                row={false}
                name='offerType'
                options={offerTypesList}
                value={offerTypesList.find(item => item.value === filters?.offerType)}
                onChange={data => {
                  changeFilter('offerType', data.value);
                }}
              />
            </Box>
          </>
        )}
        {showFilters.some(item => item === 'customerType') && (
          <>
            {!sidebarFilters && <Divider />}
            <Box>
              <Typography variant={'body6' as TypographyProps['variant']}>Type of business:</Typography>
              <MaterialRadioGroup
                row={false}
                name='customerType'
                options={offerBusinessType}
                value={offerBusinessType.find(item => item.value === filters?.customerType)}
                onChange={data => {
                  changeFilter('customerType', data.value);
                }}
              />
            </Box>
          </>
        )}
        <>
          {!sidebarFilters && <Divider />}
          <Box>
            <Typography variant={'body6' as TypographyProps['variant']}>Show offers with discount codes:</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters?.discountOffers}
                  onChange={(_, value) => {
                    changeFilter('discountOffers', value);
                  }}
                />
              }
              label={<Typography variant={'body3' as TypographyProps['variant']}>Discount codes</Typography>}
            />
          </Box>
        </>
        <>
          {!sidebarFilters && <Divider />}
          <DashboardGroupsFilter
            size={sidebarFilters ? 'small' : 'medium'}
            onChange={data => changeFilter('campaignGroupId', data)}
            withTitle={sidebarFilters}
            value={filters?.campaignGroupId?.value}
          />
        </>
        <>
          {!sidebarFilters && <Divider />}
          <AutocompleteMUI
            value={regionOptions.find(item => item.value === filters?.location?.value) || regionOptions[0]}
            options={regionOptions}
            onChange={data => {
              changeFilter('location', data);
            }}
            disabled={filters?.campaignGroupId?.group?.campaignType === 'residential'}
            label='Location'
            withTitle={sidebarFilters}
            size={sidebarFilters ? 'small' : 'medium'}
            mobileSelect
            filter
          />
        </>
        {filters?.location?.value && filters?.location?.value !== 'All' && (
          <Box>
            {!sidebarFilters && <Divider />}
            <DashboardLocalityFilter
              size={sidebarFilters ? 'small' : 'medium'}
              onChange={data => {
                changeFilter('locality', data);
              }}
              disabled={filters?.campaignGroupId?.group?.campaignType === 'residential'}
              withTitle={sidebarFilters}
              location={filters?.location?.value}
              locality={filters?.locality}
            />
          </Box>
        )}
      </Box>
    );
  }
);
