import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { GetStarted } from '@packs/components/MaterialUi/Landing/GetStarted/GetStarted';
import HeroImage from '@packs/images/ui/about-us-hero.webp';
import AboutUs1 from '@packs/images/ui/AboutUsContent1.webp';
import AboutUs2 from '@packs/images/ui/AboutUsContent2.webp';
import GetStartedIcon from '@packs/images/ui/category-landing-get-started.webp';

import useStyles from './styles';

import { Helmet } from 'react-helmet';

const description = 'BillWinner: Bringing Local Communities Closer Together!';
const AboutUs = () => {
  const styles = useStyles();

  return (
    <div>
      <Helmet>
        <title>About us</title>
        <meta name='description' content={description} />
        <meta name='og:description' content={description} />
      </Helmet>
      <Box sx={styles.heroSectionWrapper}>
        <Box sx={[styles.container, styles.heroSectionContainer]}>
          <Box sx={styles.heroLeftSection}>
            <BreadcrumbsMUI currentPage='About us' color={colors.white.main} />
            <Typography variant='h2' sx={{ mb: 2, mt: { xs: 4, md: 8 }, color: colors.white.main }}>
              BillWinner: Bringing Local Communities Closer Together!{' '}
            </Typography>
            <Typography variant='subtitle1' sx={{ color: colors.white.main }}>
              BillWinner connects local residents and local businesses onto a single platform, strengthening local
              economies while offering unbeatable value to all!
            </Typography>
          </Box>
          <Box sx={styles.heroImageContainer}>
            <img src={HeroImage} alt='Hero' />
          </Box>
        </Box>
      </Box>

      <Box sx={styles.contentWrapper}>
        <Box sx={styles.container}>
          <Box sx={styles.contentItem}>
            <Box sx={styles.contentItemImage}>
              <img src={AboutUs1} alt='AboutUs1' />
            </Box>
            <Box>
              <Typography variant='h4'>For Local Residents</Typography>
              <Box sx={styles.contentList}>
                <Typography variant='body1' sx={styles.contentListItem}>
                  <Typography variant='body2' component='span'>
                    Manage Household Bills:
                  </Typography>{' '}
                  Organise all your bills in one place for a simpler, stress-free life.
                </Typography>
                <Typography variant='body1' sx={styles.contentListItem}>
                  <Typography variant='body2' component='span'>
                    Free Expert Advice:
                  </Typography>{' '}
                  Access insights and guidance from both national and local businesses.
                </Typography>
                <Typography variant='body1' sx={styles.contentListItem}>
                  <Typography variant='body2' component='span'>
                    Exclusive Deals:
                  </Typography>{' '}
                  Unlock special offers from national and local providers with just a few clicks, saving money on
                  everyday essentials.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.contentItem}>
            <Box sx={styles.contentItemImage}>
              <img src={AboutUs2} alt='AboutUs1' />
            </Box>
            <Box>
              <Typography variant='h4'>For Local Businesses</Typography>
              <Box sx={styles.contentList}>
                <Typography variant='body1' sx={styles.contentListItem}>
                  <Typography variant='body2' component='span'>
                    Direct Connection:
                  </Typography>{' '}
                  Easily reach local residents, helping them discover the best deals in their local area.
                </Typography>
                <Typography variant='body1' sx={styles.contentListItem}>
                  <Typography variant='body2' component='span'>
                    Reputation Building:
                  </Typography>{' '}
                  Showcase your credibility through BillWinner’s upcoming local awards feature (coming soon!)
                </Typography>
                <Typography variant='body1' sx={styles.contentListItem}>
                  <Typography variant='body2' component='span'>
                    Business Offers:
                  </Typography>{' '}
                  Get exclusive offers from our partners.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <GetStarted
        icon={GetStartedIcon}
        iconWidth='579px'
        title='Join our Community!'
        description='Join our community to manage and make smarter decisions on your bills!'
      />
    </div>
  );
};

export default AboutUs;
