import colors from '@packs/assets/theme/base/colors';

export default () => ({
  container: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    px: 2
  },
  heroSectionWrapper: {
    backgroundImage: colors.secondary.gradient,
    color: colors.white.main
  },
  heroSectionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 4
  },
  heroLeftSection: {
    maxWidth: '645px',
    width: '100%',
    pt: 4,
    pb: {
      xs: 4,
      md: 15
    }
  },
  heroImageContainer: {
    display: {
      xs: 'none',
      md: 'block'
    },
    width: "calc('100% - 645px')",
    mb: '-60px'
  },
  contentWrapper: {
    pt: {
      xs: 8,
      md: 15
    },
    pb: {
      xs: 8,
      md: 32
    },
    backgroundColor: colors.white.main
  },
  contentItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: {
      xs: 3,
      md: 8
    },
    mb: 8,
    '&:nth-child(odd)': {
      flexDirection: {
        xs: 'column',
        md: 'row-reverse'
      }
    }
  },
  contentList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 3
  },
  contentListItem: {
    position: 'relative',
    pl: 4,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: colors.primary.main
    }
  },
  contentItemImage: {
    width: '100%',
    maxWidth: '450px'
  }
});
